
import { Component, Vue } from 'vue-property-decorator';
import { agreements } from '@/store/modules/agreements';
import { getDocument, getDocumentsList } from '@/api/document';
import { Document } from '@/api/models/document';
import {
  AGREEMENT_CONTRACT_ONLINE_SIGNATURE,
  AGREEMENT_DOCUMENT_TYPE,
  AgreementDocuments,
  ApplicationDocuments,
  CONSENT_DOCUMENTS_TYPES,
  SINGLE_AGREEMENT_DOCUMENTS_TYPES,
  UNIFIED_AGREEMENT_DOCUMENTS_TYPES
} from '@/views/profile/Documents/type';
import { DocumentService } from '@/services/document';
import { debtSettlementApplication } from '@/store/modules/debtSettlementApplication';
import { DebtSettlementApplication } from '@/api/models/debtSettlementApplication';

@Component({
  name: 'ProfileDocumentsPage'
})
export default class extends Vue {
  private loading: string[] = [];
  private agreementsDocuments: AgreementDocuments[] = [];
  private openedApplicationsDocuments: ApplicationDocuments[] = [];
  private clientDocuments: Document[] = [];
  private filesDownloading: string[] = [];

  async mounted() {
    await agreements.initGetAgreementList();
    await debtSettlementApplication.initGetDebtSettlementApplications({ force: true });
  }

  documentName(file: Document) {
    if (AGREEMENT_CONTRACT_ONLINE_SIGNATURE.includes(file.type)) {
      const suffix = file.metadata.signed_fio ? ` (${file.metadata.signed_fio})` : '';

      return this.$t(`PROFILE_MY_DOCUMENTS.DOCUMENTS.${file.type}`) + suffix;
    }

    return this.$t(`PROFILE_MY_DOCUMENTS.DOCUMENTS.${file.type}`);
  }

  get agreements() {
    return agreements.getAgreements;
  }

  get applications() {
    return debtSettlementApplication.getDebtSettlementApplications;
  }

  agreementDocuments(agreementId: string): Document[] {
    return this.agreementsDocuments.find(ad => ad.agreementId === agreementId)?.documentList ?? [];
  }

  debtSettlementApplicationDocuments(applicationId: string): Document[] {
    return this.openedApplicationsDocuments.find(ad => ad.applicationId === applicationId)?.documentList ?? [];
  }

  tabLoading(id: string): boolean {
    return this.loading.includes(id);
  }

  async handleOpenAgreementTab(agreementId: string) {
    if (this.debtSettlementApplicationDocuments(agreementId).length === 0) {
      this.loading.push(agreementId);
      await this.fetchAgreementDocuments(agreementId);
      setTimeout(() => (this.loading = this.loading.filter(a => a !== agreementId)), 1000);
    }
  }

  async handleOpenClientTab() {
    if (this.clientDocuments.length === 0) {
      this.loading.push('client-documents');
      await this.fetchClientDocuments();
      setTimeout(() => (this.loading = this.loading.filter(id => id !== 'client-documents')), 1000);
    }
  }

  async handleOpenApplicationTab(application: DebtSettlementApplication) {
    if (this.debtSettlementApplicationDocuments(application.id).length === 0) {
      this.loading.push(application.id);
      await this.fetchDebtSettlementApplicationDocuments(application);
      setTimeout(() => (this.loading = this.loading.filter(a => a !== application.id)), 1000);
    }
  }

  async fetchDebtSettlementApplicationDocuments(application: DebtSettlementApplication) {
    const initialDocuments = await Promise.all((application.payload.documentsList ?? []).map(getDocument));
    const { data: documentList } = await getDocumentsList({ metadata: { applicationId: application.id } });
    const applicationDocuments = this.openedApplicationsDocuments.find(ad => ad.applicationId === application.id);
    documentList.push(...initialDocuments.map(({ data }) => data));

    if (applicationDocuments) {
      applicationDocuments.documentList = documentList;
    } else {
      this.openedApplicationsDocuments.push({ applicationId: application.id, documentList });
    }
  }

  async fetchAgreementDocuments(agreementId: string) {
    const { data: documentList } = await getDocumentsList({ metadata: { agreementId: agreementId } });

    const hasUnifiedDocs =
      documentList.filter(document => UNIFIED_AGREEMENT_DOCUMENTS_TYPES.includes(document.type)).length > 0;
    const documents = hasUnifiedDocs
      ? documentList.filter(document => !SINGLE_AGREEMENT_DOCUMENTS_TYPES.includes(document.type))
      : documentList;

    const agreementDocuments = this.agreementsDocuments.find(ad => ad.agreementId === agreementId);
    if (agreementDocuments) {
      agreementDocuments.documentList = documents.filter(
        document => (document.type as string) !== (AGREEMENT_DOCUMENT_TYPE.AGREEMENT_CONTRACT_AMENDMENT as string)
      );
    } else {
      this.agreementsDocuments.push({ agreementId: agreementId, documentList: documents });
    }
  }

  async fetchClientDocuments() {
    const { data: clientDocuments } = await getDocumentsList({ types: CONSENT_DOCUMENTS_TYPES });
    clientDocuments.forEach(document => {
      if (!this.clientDocuments.some(d => d.type === document.type)) {
        this.clientDocuments.push(document);
      }
    });
  }

  async open(documentId: string) {
    await this.handleDocument(documentId, () => DocumentService.open(documentId));
  }

  async download(documentId: string) {
    await this.handleDocument(documentId, () => DocumentService.download(documentId));
  }

  async handleDocument(documentId: string, action: () => void) {
    if (this.filesDownloading.includes(documentId)) {
      return;
    }

    this.filesDownloading.push(documentId);
    try {
      await action();
    } catch ({ response }) {
      this.$notify.error(this.$t('NOTIFICATION.DOCUMENT_UPLOAD.ERROR').toString());
    } finally {
      this.filesDownloading = this.filesDownloading.filter(file => file !== documentId);
    }
  }
}
